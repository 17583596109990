import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#181019', // Colore principale = Nero
            dark: '#0f0a0f', // Colore scuro - Nero molto scuro
            light: '#2e2532', // Colore chiaro - Nero chiaro
        },
        secondary: {
            main: '#00FF00',
            dark: '#00FF00',
            light: '#00FF00',
        },
    },
    typography: {
        fontFamily: "Jaro, sans-serif", // Sostituisci con il font che desideri usare
    },
});

export default defaultTheme;