import { IOutput } from "./interfaces/index";
import React from "react";

export class TerminalState {
  state: { history: IOutput[] } = { history: [] };

  constructor() {
    const savedHistory = localStorage.getItem("terminalHistory");
    if (savedHistory) {
      this.state.history = JSON.parse(savedHistory).map((item: IOutput) => ({
        ...item,
        message: typeof item.message === 'string' ? item.message : JSON.stringify(item.message)
      }));
    }
  }

  private saveHistory(): void {
    localStorage.setItem("terminalHistory", JSON.stringify(this.state.history));
  }

  addOutput(output: IOutput): void {
    this.state.history.push({
      ...output,
      message: typeof output.message === 'string' ? output.message : JSON.stringify(output.message)
    });
    this.saveHistory();
  }

  addUserInput(input: string): void {
    this.addOutput({
      type: "user",
      message: input,
      timestamp: new Date(),
    });
  }

  addSystemResponse(response: string | JSX.Element): void {
    this.addOutput({
      type: "system",
      message: typeof response === 'string' ? response : JSON.stringify(response),
      timestamp: new Date(),
    });
  }

  clearHistory(): void {
    this.state.history = [];
    this.saveHistory();
  }

  getHistory(): IOutput[] {
    return this.state.history.map((item) => ({
      ...item,
      message: this.parseMessage(item.message)
    }));
  }

  private parseMessage(message: string | JSX.Element): string | JSX.Element {
    try {
      return JSON.parse(message as string);
    } catch {
      return message;
    }
  }
}