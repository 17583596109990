import { TerminalState } from "./classes/TerminalState";

export const InitTermSystem = () => {

    const ASCII_ART = `
         _            _            _               _      
        /\\ \\         /\\ \\         / /\\            /\\ \\    
       /  \\ \\       /  \\ \\       / /  \\          /  \\ \\   
      / /\\ \\ \\     / /\\ \\ \\     / / /\\ \\        / /\\ \\ \\  
     / / /\\ \\ \\   / / /\\ \\ \\   / / /\\ \\ \\      / / /\\ \\_\\ 
    / / /  \\ \\_\\ / / /  \\ \\_\\ / / /\\ \\_\\ \\    / /_/_ \\/_/ 
   / / /   / / // / /   / / // / /\\ \\ \\___\\  / /____/\\    
  / / /   / / // / /   / / // / /  \\ \\ \\__/ / /\\____\\/    
 / / /___/ / // / /___/ / // / /____\\_\\ \\  / / /______    
/ / /____\\/ // / /____\\/ // / /__________\\/ / /_______\\   
\\/_________/ \\/_________/ \\/_____________/\\/__________/   
                                                           
    `;

    const ASCII_ART_MOBILE = `
   ___   ___  ___ ___ 
  / _ \ / _ \| _ ) __|
 | (_) | (_) | _ \ _| 
  \___/ \___/|___/___|
  
                      `;

    const BIOS_INFO = (
        `
    Welcome to OOBE AI terminal - Loading system information...
    Success! System information loaded.
    Machine: OOBE X 13 9010
    Capacity: 1TB SSD, 16GB RAM, 8-core CPU
    Type 'help' to get started
        `
    );

    return { ASCII_ART, ASCII_ART_MOBILE, BIOS_INFO };
};
