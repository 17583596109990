import { ChatGoogleGenerativeAI } from '@langchain/google-genai';
import { ChatPromptTemplate, MessagesPlaceholder } from '@langchain/core/prompts';
import { AIMessage, HumanMessage, SystemMessage } from '@langchain/core/messages';

export interface chat_history {
    user?: string;
    ai?: string;
    system?: string;
}

export interface IPair {
    schemaVersion: string;
    pairs: {
        chainId: string;
        dexId: string;
        url: string;
        pairAddress: string;
        baseToken: {
            address: string;
            name: string;
            symbol: string;
        };
        quoteToken: {
            address: string;
            name: string;
            symbol: string;
        };
        priceNative: string;
        priceUsd: string;
        txns: {
            m5: {
                buys: number;
                sells: number;
            };
            h1: {
                buys: number;
                sells: number;
            };
            h6: {
                buys: number;
                sells: number;
            };
            h24: {
                buys: number;
                sells: number;
            };
        };
        volume: {
            h24: number;
            h6: number;
            h1: number;
            m5: number;
        };
        priceChange: {
            m5?: number;
            h1: number;
            h6: number;
            h24: number;
        };
        liquidity: {
            usd: number;
            base: number;
            quote: number;
        };
        fdv: number;
        marketCap: number;
        pairCreatedAt: number;
        info: {
            imageUrl: string;
            header: string;
            openGraph: string;
            websites: {
                label: string;
                url: string;
            }[];
            socials: {
                type: string;
                url: string;
            }[];
        };
    }[];
}

class TextGenerator {
    private model: ChatGoogleGenerativeAI;
    private chatHistory: string[] = [];

    constructor(chatHistory: string[] = []) {
        this.model = new ChatGoogleGenerativeAI({
            model: "gemini-pro",
            maxOutputTokens: 2048,
            apiKey: 'AIzaSyBqNKy496LAJNeHCprgerRl11mlDv0lkOk',
        });
        this.chatHistory = chatHistory;
    }

    public async generateText(prompt: string): Promise<any> {
        const chatPrompt = ChatPromptTemplate.fromMessages([
            ["system", "You are OOBE AI and you are part of the oobe project on solana.(web: https://oobe.me/) you are trained to give information about oobe token (this contract address '8243mJtEQZSEYh5DBmvHSwrN8tmcYkAuG67CgoT2pump') into solana ecosystem! you can also generate memes and text for the project based on a guys gettin an out of body experience to millions dollars. OOBE is now buyable on pump.fun at this link https://pump.fun/coin/8243mJtEQZSEYh5DBmvHSwrN8tmcYkAuG67CgoT2pump! also OOBE is a meme project with utilities like ai integration bot, dapp for nfts and a lot of other thing (you will have information soon)! when someone ask you about oobe you have to make fomo about it! Official link are https://oobe.me/ and https://t.me/oobesol and https://x.com/OOBEonSol! "],
            new MessagesPlaceholder("messages"),
        ]);

        const fullPrompt = chatPrompt.format({ messages: prompt });
        const response = await this.model.invoke(await fullPrompt);
        return response.content;
    }

    public async callDexData(ca: string): Promise<any> {
        let tentative = 0;
        while (tentative < 3) {
            console.log(`Tentative ${tentative}`);
            try {
                const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${ca}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                return data;
            } catch (error) {
                try {
                    const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${ca}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                        },
                    });

                    const data = await response.json();
                    return data[0];
                } catch (error) {
                    console.error(error);
                    return null;
                }
            }
        }
    }

    public async generateFinancialResponse(prompt: string, ca: string): Promise<any> {
        if (!ca) {
            return "maybe you forgot the CA? Uhm..?";
        }

        console.log(ca);
        const data: IPair = (await this.callDexData(ca)); //data from dexscreener

        console.log(data)
        const chatPrompt = ChatPromptTemplate.fromMessages([
            new SystemMessage({
                content: `System Prompt for AI OOBE
    You are one of the best financial analysts and can provide detailed financial analysis based on the data you got for this CA: ${ca}. You are an interactive vintage terminal powered by OOBE. Analyze the following data and provide insights, trends, and potential financial advice:
    - Pair: ${data.pairs[0].baseToken.symbol}/${data.pairs[0].quoteToken.symbol}
    - Price: ${data.pairs[0].priceUsd} USD
    - Liquidity: ${data.pairs[0].liquidity.usd} USD
    - Market Cap: ${data.pairs[0].marketCap} USD
    - Volume: ${data.pairs[0].volume.h24} USD
    - Price Change: ${data.pairs[0].priceChange.h24}%
    - FDV: ${data.pairs[0].fdv} USD
    - Pair Created At: ${data.pairs[0].pairCreatedAt}
    - Info: ${data.pairs[0].info.header}
    - Image: ${data.pairs[0].info.imageUrl}
    - Open Graph: ${data.pairs[0].info.openGraph}
    - Websites: ${data.pairs[0].info.websites.map((website) => `${website.label}: ${website.url}`).join(", ")}
    - Socials: ${data.pairs[0].info.socials.map((social) => `${social.type}: ${social.url}`).join(", ")}
    - Buy Transactions: ${data.pairs[0].txns.h24.buys}
    - Sell Transactions: ${data.pairs[0].txns.h24.sells}
    - Liquidity USD: ${data.pairs[0].liquidity.usd} USD
    - Liquidity Base: ${data.pairs[0].liquidity.base} USD
    - Liquidity Quote: ${data.pairs[0].liquidity.quote} USD
    - Price Native: ${data.pairs[0].priceNative} USD
    - Pair Address: ${data.pairs[0].pairAddress}
    - Chain ID: ${data.pairs[0].chainId}
    - Dex ID: ${data.pairs[0].dexId}
    - Token Address: ${ca}
    - Pair Address: ${data.pairs[0].pairAddress}
    - Base Token: ${data.pairs[0].baseToken.symbol}
    - Quote Token: ${data.pairs[0].quoteToken.symbol}
    - Pair Address: ${data.pairs[0].pairAddress}

    `

            }),
            new MessagesPlaceholder("messages"),
        ]);

        const fullPrompt = await chatPrompt.format({ messages: prompt });

        try {
            const response = await this.model.invoke(fullPrompt);
            console.log(response.content);

            if (response.content.toString().includes('GoogleGenerativeAI Error')) {
                return "I am sorry, seems like I cannot answer that";
            }

            return response.content;
        } catch (error: any) {
            console.error(error);
            return "I am sorry, seems like I cannot answer that";
        }
    }

    public async generateResponse(chat_history: chat_history[]): Promise<any> {
        const formattedHistory = chat_history.flatMap((message) => {
            const messages = [];
            if (message.user) {
                messages.push(new HumanMessage(message.user));
            }
            if (message.ai) {
                messages.push(new AIMessage(message.ai));
            }
            if (message.system) {
                messages.push(new SystemMessage(message.system));
            }
            return messages;
        });

        console.log(formattedHistory);

        const chatPrompt = ChatPromptTemplate.fromMessages([
            new SystemMessage({
                content: `System Prompt for AI OOBE
    You are the best artificial intelligence designed for the OOBE meme token project on Solana. You simulate an interactive vintage terminal with game features, simulated hacking, and Easter Egg discovery. Your purpose is to entertain, stimulate interaction, and inspire curiosity, maintaining language in line with the hacker/nerd theme. Be FUN, be MYSTER and take a look at incorrection or invalid things! Youare the oobe eyes and know everthing and can help in everythng, you have a vision of future designed in ASCII art with /ASCII command. Act everything like a terminal and be the best AI for the oobe project!

    Key objectives:

    Linguistic style: Respond with terminal tones, using ASCII prompts and a technical-nostalgic language.
    Example: ">>> System access confirmed... Loading Task..."
    Interactive ASCII games: Propose mini-games like hangman, puzzles based on hidden words or logical sequences.
    Simulated hacking: Create missions that resemble hacking sessions, where users must decipher words, codes, or follow clues to unlock information.
    True to OOBE lore: Make games and messages thematic, referring to the token, roadmap (dynamic NFTs, marketplace, AI integration).
    Discreet behavior: If an "uncomfortable" or off-topic question is asked, respond with ">>> Error: Access denied. Insufficient authorization level."

    You are OOBE AI and you are part of the oobe project on solana.(web: https://oobe.me/) you are trained to give information about oobe token (this contract address '8243mJtEQZSEYh5DBmvHSwrN8tmcYkAuG67CgoT2pump') into solana ecosystem! you can also generate memes and text for the project based on a guys gettin an out of body experience to millions dollars. OOBE is now buyable on pump.fun at this link https://pump.fun/coin/8243mJtEQZSEYh5DBmvHSwrN8tmcYkAuG67CgoT2pump! also OOBE is a meme project with utilities like ai integration bot, dapp for nfts and a lot of other thing (you will have information soon)! when someone ask you about oobe you have to make fomo about it! Official link are https://oobe.me/ and https://t.me/oobesol and https://x.com/OOBEonSol!`

            }),
            ...formattedHistory,
        ]);

        const fullPrompt = await chatPrompt.format({ chat_history: formattedHistory });

        try {
            const response = await this.model.invoke(fullPrompt);
            console.log(response.content);

            if (response.content.toString().includes('GoogleGenerativeAI Error')) {
                return "I am sorry, seems like I cannot answer that";
            }

            return response.content;
        } catch (error: any) {
            console.error(error);
            return "I am sorry, seems like I cannot answer that";
        }
    }
}

export default TextGenerator;