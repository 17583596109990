import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import CoinGecko from '../../assets/svgs/coingecko-1.svg';
import CoinMarketCap from '../../assets/svgs/coinmarketcap-1.svg';
import Solscan from '../../assets/svgs/solana.svg';
import Dextool from '../../assets/svgs/dex.svg';
import WTP from '../../assets/svgs/wtp.jpg';

const partners = [
    {
        name: 'CoinGecko',
        logo: CoinGecko,
    },
    {
        name: 'CoinMarketCap',
        logo: CoinMarketCap,
    },
    {
        name: 'Solscan',
        logo: Solscan,
    },
    {
        name: 'Dex',
        logo: Dextool,
    },
    {
        name: 'WTP',
        logo: WTP,
    },
];

const Partners: React.FC = () => {
    let isMobile = useMediaQuery('(max-width: 798px)');
    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                maxWidth: isMobile ? '420px' : '100%',
                height: '80px', // Altezza ottimale per il carosello
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    whiteSpace: 'nowrap',
                }}
            >
                {partners.map((partner, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'inline-block',
                            textAlign: 'center',
                            mx: 1.5,
                            minWidth: isMobile ? '20px' : '100px', // Per uniformità
                        }}
                    >
                        <img
                            src={partner.logo}
                            alt={partner.name}
                            style={{
                                width: '60px',
                                height: '60px',
                                filter: 'grayscale(100%)',
                            }}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Partners;
