import React, { useEffect, useRef } from "react";
import Typewriter from "./TypeWrite";

interface TextTerminalAnimationProps {
    text: string;
}

const TextTerminalAnimation: React.FC<TextTerminalAnimationProps> = ({ text }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const typewriterRef = useRef<Typewriter | null>(null);

    useEffect(() => {
        if (containerRef.current && !typewriterRef.current) {
            typewriterRef.current = new Typewriter(containerRef.current, {
                loop: false,
                typingSpeed: 10,
                deletingSpeed: 50,
            });

            typewriterRef.current
                .typeString('∏ ∑ ۝ ▓ ▲')
                .pauseFor(200)
                .deleteChars(1)
                .typeString('▓')
                .pauseFor(700)
                .deleteChars(2)
                .typeString('▲')
                .pauseFor(500)
                .deleteChars(3)
                .typeString('∏ ∑ ')
                .pauseFor(500)
                .deleteChars(4)
                .pauseFor(500)
                .deleteChars(5)
                .typeString('۝ ')
                .typeString(text)
                .changeColor("~OOBE-AI", "red")
                .changeColorStartEnd(0, 12, "aqua")
                .start()
        }
    }, [text]);

    return <div ref={containerRef} style={{ display: "inline" }} />;
};

export default TextTerminalAnimation;