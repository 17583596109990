// classes/CommandRegistry.ts
import { ICommand } from "./interfaces/index";

export class CommandRegistry {
  private commands: Map<string, ICommand> = new Map();

  registerCommand(command: ICommand): void {
    this.commands.set(command.name, command);
  }

  getCommand(name: string): ICommand | undefined {
    return this.commands.get(name);
  }

  getAllCommands(): ICommand[] {
    return Array.from(this.commands.values());
  }
}
