import React from "react";
import { IOutput } from "./classes/interfaces";
import { Box, Typography } from "@mui/material";
import TextTerminalAnimation from "./functions/animations/TextTerminalAnimation";

interface OutputDisplayProps {
    history: IOutput[];
}

const OutputDisplay: React.FC<OutputDisplayProps> = ({ history }) => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            padding: "0.5rem",
            overflowY: "auto",
            height: "100%",
            bgColor: "#0A0A0A",
        }}>
            {history.map((output, index) => (
                <Typography
                    key={index}
                    sx={{
                        color: output.type === "system" ? "green" : "cyan",
                        textAlign: "left",
                        whiteSpace: "pre-wrap", // Ensure the message respects formatting and line breaks
                    }}
                >
                    {typeof output.message === "string" ? (
                        output.type === "user" ? ( 
                            <div>
                                <span style={{ color: "cyan" }}>
                                [{new Date(output.timestamp).toLocaleTimeString()}] <span style={{ color: "#00A3A3" }}> ~USER </span>  - {output.message}
                                </span>
                            </div>
                        ) : (
                            <TextTerminalAnimation key={index} text={`[${new Date(output.timestamp).toLocaleTimeString()}] ~OOBE-AI - ${output.message}`} />
                        )
                    ) : (
                        React.isValidElement(output.message) ? output.message : typeof output.message === "object" && output.message !== null && "type" in output.message && output.message.type === "pre" ? React.createElement(output.message.type, output.message.props) : JSON.stringify(output.message)
                    )}
                </Typography>
            ))}
        </Box>
    );
};

export default OutputDisplay;