import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, useMediaQuery } from '@mui/material';
import { TerminalState } from './classes/TerminalState';
import { CommandExecutor } from './classes/CommandExecutor';
import { CommandRegistry } from './classes/CommandRegistry';
import OutputDisplay from './OutputDisplay';
import { InitTermSystem } from './Bios';

const Terminal: React.FC = () => {
    const [messages, setMessages] = useState<{ text: string; isUser: boolean }[]>([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [visibleChars, setVisibleChars] = useState<number[]>([]);
    const [showCursor, setShowCursor] = useState<boolean>(true); // For blinking cursor
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const [terminalState] = useState(new TerminalState());
    const [commandRegistry] = useState(new CommandRegistry());
    const [executor] = useState(new CommandExecutor(commandRegistry, terminalState));
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const handleCommandExecution = async (_input: string) => {
        let input = _input;

        const solanaAddressPattern = /[1-9A-HJ-NP-Za-km-z]{32,44}/;
        if (solanaAddressPattern.test(input)) {
            // Handle Solana token address
            terminalState.addSystemResponse('Valid Solana token address detected.');
            const ca = input.match(solanaAddressPattern);
            input = ca ? `solana-token-info ${ca[0]}` : input;
        }
        // Aggiungi l'input dell'utente
        terminalState.addUserInput(input);

        setInput('');
        // Esegui il comando e ottieni il risultato
        const result = await executor.executeCommand(input);

        // Aggiungi l'output del sistema
        terminalState.addSystemResponse(result);

        // Aggiorna i messaggi per il rendering
        setMessages(terminalState.getHistory().map((item) => ({
            text: typeof item.message === 'string' ? item.message : '',
            isUser: item.type === 'user'
        })));
    };

    useEffect(() => {
        terminalState.clearHistory();
        setMessages([]);
    }, []);

    // Scroll to the bottom when a new message is added
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    // Typing effect for AI messages
    useEffect(() => {
        const aiMessages = messages.filter((msg) => !msg.isUser);
        const lastAiMessageIndex = aiMessages.length - 1;

        if (lastAiMessageIndex >= 0 && visibleChars[lastAiMessageIndex] < aiMessages[lastAiMessageIndex]?.text.length) {
            const timer = setInterval(() => {
                setVisibleChars((prev) => {
                    const updated = [...prev];
                    updated[lastAiMessageIndex] = updated[lastAiMessageIndex] + 1;
                    return updated;
                });
            }, 50);

            return () => clearInterval(timer);
        }
    }, [visibleChars, messages]);

    // Blink cursor effect
    useEffect(() => {
        const cursorTimer = setInterval(() => {
            setShowCursor((prev) => !prev);
        }, 500);

        return () => clearInterval(cursorTimer);
    }, []);

    const isMobile = useMediaQuery('(max-width: 760px)');

    let innerHeight = window.innerHeight;

    useEffect(() => {
        innerHeight = window.innerHeight;
    }, []);

    function startTerminalInfo() {
        const { ASCII_ART, ASCII_ART_MOBILE, BIOS_INFO } = InitTermSystem();

        const ASCIItoLoad = isMobile ? ASCII_ART_MOBILE : ASCII_ART;

        terminalState.addSystemResponse(<pre>{ASCIItoLoad}</pre>);
        terminalState.addSystemResponse(BIOS_INFO);
    }

    useEffect(() => {
        if (terminalState.getHistory().length === 0) {
            startTerminalInfo();
        }
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');
        if (!canvas || !ctx) return;

        const width = canvas.width = window.innerWidth;
        const height = canvas.height = window.innerHeight;
        const columns = Math.floor(width / 20);
        const drops = Array(columns).fill(1);

        const draw = () => {
            ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
            ctx.fillRect(0, 0, width, height);
            ctx.fillStyle = '#0F0';
            ctx.font = '20px monospace';

            for (let i = 0; i < drops.length; i++) {
                const text = String.fromCharCode(Math.random() * 128);
                ctx.fillText(text, i * 20, drops[i] * 20);
                if (drops[i] * 20 > height && Math.random() > 0.975) {
                    drops[i] = 0;
                }
                drops[i]++;
            }
        };

        const interval = setInterval(draw, 33);
        return () => clearInterval(interval);
    }, []);

    return (
        <Box
            sx={{
                height: isMobile ? window.innerHeight - 10 : window.innerHeight,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                bgcolor: '#0A0A0A', // Kali Linux background
                color: '#00FF00', // Kali Linux text color
                fontFamily: '"Courier New", Courier, monospace', // Terminal-style font
                padding: '20px',
                fontSize: '1.2rem', // Font size resembling Kali Linux terminal
                letterSpacing: '1px', // Subtle letter spacing
                boxSizing: 'border-box',
                overflowY: 'hidden',
                overflowX: 'hidden',
                position: 'relative'
            }}
        >
            <canvas
                ref={canvasRef}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 0,
                    pointerEvents: 'none'
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '5px 15px',
                    borderBottom: '1px solid #333333',
                    bgcolor: '#0A0A0A',
                    color: '#FFFFFF',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    fontSize: '1.3rem',
                    zIndex: 1
                }}
            >
                <Box sx={{ color: '#00FF00' }}>OOBE TERMINAL</Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        sx={{
                            color: '#00FF00', // green
                            textTransform: 'none',
                            fontSize: '1rem',
                            marginRight: '15px',
                        }}
                        href="/main"
                    >
                        Home
                    </Button>
                </Box>
            </Box>
            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                padding: '5px',
                marginTop: '10px',
                width: '100%',
                zIndex: 999,
                backgroundColor: 'rgba(0,0,0, 0.8)'
            }}>
                <OutputDisplay history={terminalState.getHistory()} />
                <div ref={messagesEndRef} />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    padding: '10px',
                    bgcolor: '#0A0A0A',
                    alignItems: 'center',
                    borderTop: '1px solid #333333',
                    zIndex: 1
                }}
            >
                <Box sx={{ color: '#00FF00', mr: 1 }}>{'user >'} </Box>
                <TextField
                    fullWidth
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleCommandExecution(input)}
                    variant="outlined"
                    placeholder="Type a command..."
                    InputProps={{
                        style: {
                            color: '#00FF00', // Green text
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: '1.2rem', // Font size resembling Kali Linux terminal
                        },
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: '#333333' },
                            '&:hover fieldset': { borderColor: '#00FF00' },
                            '&.Mui-focused fieldset': { borderColor: '#00FF00' },
                        },
                    }}
                />
                <Button
                    onClick={() => handleCommandExecution(input)}
                    sx={{
                        marginLeft: '10px',
                        bgcolor: '#333333',
                        color: '#00FF00', // Green text
                        fontFamily: '"Courier New", Courier, monospace',
                        fontSize: '1.2rem', // Font size resembling Kali Linux terminal
                        ':hover': {
                            bgcolor: '#444444',
                        },
                    }}
                >
                    Send
                </Button>
            </Box>
        </Box>
    );
};

export default Terminal;