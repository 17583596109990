import { CommandRegistry } from "./CommandRegistry";
import TextGenerator, { chat_history } from "./neural/neural";
import { TerminalState } from "./TerminalState";

export class CommandExecutor {
    private textGenerator: TextGenerator = new TextGenerator();
    constructor(private registry: CommandRegistry, private terminalState: TerminalState) {
        this.textGenerator = new TextGenerator();
    }

    async executeCommand(input: string): Promise<any> {
        const command = this.registry.getCommand(input);
        const solanaAddressPattern = /[1-9A-HJ-NP-Za-km-z]{32,44}/;

        const convertDataToHistory = (): chat_history[] => {
            const history = this.terminalState.getHistory();
            const obj: chat_history[] = history.map(item => ({
                ai: item.type === "system" ? (typeof item.message === 'string' ? item.message : '') : '',
                user: item.type === "user" ? (typeof item.message === 'string' ? item.message : '') : '',
            }));
            return obj;
        }

        console.log(convertDataToHistory());

        try {
            if (solanaAddressPattern.test(input)) {
                // Handle Solana token address
                const ca = input.match(solanaAddressPattern);
                input = ca ? `${ca[0]}` : input;
                console.log(ca, input);
                if (ca) {
                    return await this.textGenerator.generateFinancialResponse(input, ca[0]);
                } else {
                    throw new Error("Invalid Solana address");
                }
            } else {
                console.log("NO SOLANA ADDRESS");
                return await this.textGenerator.generateResponse(convertDataToHistory());
            }
        } catch (error) {
            return `Error: ${error instanceof Error ? error.message : "Unknown error"}`;
        }
    }
}